/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin-top: -1.5em;
  right: 36px;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #1f1f1f;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  margin-top: -1.5em;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  color: black;
  overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #f1f1f1;
}

/* Wrapper for item list */
.bm-item-list {
  color: rgb(0, 76, 152);
  padding: 0.8em;
  float: right;
  text-align: right;
}

/* Styling of overlay */
.bm-overlay {
  display: none;
}

.menu-item {
  color: #1f1f1f;
  font-family: "Merriweather", serif;
  font-size: 1.5em;
  font-weight: 700;
  margin: 10px 0;
  text-decoration: none;
}

a:hover {
  color: gray;
}

.menu-item--small {
  color: #1f1f1f;
  font-size: 16px;
  margin: 10px 0;
  text-decoration: none;
}

.menu__break {
  margin: 30px 0;
}

.social-list {
  color: #1f1f1f;
}

.social-link {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 100;
  font-size: 0.8rem;
  text-decoration: none;
  color: #1f1f1f;
}

.nav-footer {
  font-size: 0.7rem;
  font-weight: 100;
  color: gray;
}

/* Mobile Responsive */

@media screen and (max-width: 420px) {
  .bm-burger-button {
    width: 20px;
    height: 15px;
    right: 18px;
    top: 18px;
  }
  .bm-menu-wrap {
    width: 190px !important;
  }
  .bm-menu {
    margin-top: -1.5em;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow-y: scroll;
  }
  .bm-cross-button {
    margin-top: -0.7rem;
    right: 18px;
    height: 12px;
    width: 12px;
  }
  .menu-item {
    font-size: 1.2rem;
    margin: 7px 0;
  }
}
