/* Project-Items CSS */

.project-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.project-item {
  margin: 20px 0;
}

/* .project-item img {
  float: left;
  max-width: 50%;
  margin: 10px 0;
} */

.project-details {
  height: auto;
  padding: 5px;
  margin: 5px 0 0 0;
  text-align: center;
}

.project-breaker {
  width: 25%;
  margin-top: 20px;
}

.tech-stack-header,
.role-header {
  margin: 0;
}

.tech-list {
  margin-top: -10px;
  padding: 0;
}

.tech-list > li {
  list-style-type: none;
  display: inline-flex;
  margin: 0;
  padding: 0 5px;
}

li:first-child {
  padding-left: 0;
}

.project-description,
.role-task {
  margin-top: -10px;
  font-style: italic;
  font-size: 0.9rem;
}

.role {
  margin-top: 5px;
}

.links-header {
  margin-bottom: 5px;
}

.github-link,
.livesite-link {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700;
  padding: 10px;
  text-decoration: none;
  font-size: 1rem;
  color: black;
}

/* .github-link {
  background: black;
  color: white;
}

.github-link :hover {
  background: grey;
  color: white;
}

.livesite-link {
  background: blue;
  color: white;
}

.livesite-link :hover {
  background: black;
  color: white;
} */
