/* Technologies Items CSS */

.techlist {
  list-style-type: none;
  margin: 1em auto;
  padding: 0;
  width: 70%;
  text-align: center;
}

.tech {
  font-size: 1.3rem;
  margin-bottom: -0.5em;
}

.tech-details {
  margin-top: -1.3em;
  font-size: 0.7rem;
}

.tech-breaker {
  width: 10%;
  margin-top: 20px;
}
