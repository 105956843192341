/* Background CSS */

.breaker {
  width: 25%;
  margin-top: 20px;
}

.background-bio {
  text-align: center;
  margin: auto;
  padding: 0;
  max-width: 50em;
}
