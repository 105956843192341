/* Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,700,800|Merriweather:300,400,900");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
  font-weight: 900;
}

p {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 100;
}

.wrapper {
  margin: 2em 0;
  padding: 0 2em;
}

.container {
  text-align: center;
  margin: auto;
  padding: 0 2em;
}

.page-header {
  margin: auto;
  padding: 0;
}

/* Mobile Sizes */

@media screen and (max-width: 420px) {
  :root {
    font-size: 12px;
  }
  .wrapper {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .container {
    padding: 0 1rem;
  }
}
