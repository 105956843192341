/* Contact CSS */

input {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 100;
  font-size: 1rem;
  width: 25%;
  height: 20px;
  text-align: center;
  border: none;
}

textarea {
  width: 25%;
  height: 25px;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 100;
  font-size: 1rem;
  border: none;
  resize: none;
}

button {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-size: 1.15rem;
  color: white;
  background-color: black;
}

button :hover {
  color: gray;
}

.social-link-list {
  list-style-type: none;
  display: inline;
  margin: 0;
  padding: 0 5px;
  color: black;
}

.social-link {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 100;
  font-size: 1.15rem;
  padding: 0 10px;
  text-decoration: none;
  color: black;
}

li:first-child {
  padding-left: 0;
}
