/* main header */
.top {
  margin: 2em 0;
  padding: 0 2em;
}
.name-header {
  font-family: "Merriweather", serif;
  font-weight: 900;
  font-size: 5rem;
  line-height: 0.25em;
  margin-bottom: 0.25em;
}

.tagline {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 700;
  font-size: 1.82rem;
}

/* Mobile Sizes */

@media screen and (max-width: 420px) {
  :root {
    font-size: 12px;
  }
  .top {
    margin: 2rem 0;
    padding: 0 1rem;
  }
  .name-header {
    font-size: 3.5rem;
  }
  .tagline {
    font-size: 1.2rem;
  }
}
