/* Home Nav Links*/

.homenav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 0%;
  text-align: center;
}

.item {
  color: #1f1f1f;
  font-family: "Merriweather", serif;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10px 0;
  text-decoration: none;
}

li a:hover {
  color: gray;
}

@media screen and (max-width: 420px) {
  .item {
    font-size: 1.15rem;
  }
}
